






import { Component, Vue } from 'vue-property-decorator'
import UserService from '@/services/user'

interface QueryParams {
  id_token?: string
  refresh_token?: string
  state?: string
  token_type?: string
}

@Component({})
export default class index extends Vue {
  parseQueryParams(search: string): QueryParams {
    const params = new URLSearchParams(search)
    const query: any = {} // 使用any来避免类型错误，稍后将进行类型断言
    params.forEach((value, key) => {
      query[key] = value
    })
    return query as QueryParams
  }

  mounted() {
    // 从window.location中获取查询参数
    const search = window.location.hash.substring('#/callback#'.length)
    const queryParams = this.parseQueryParams(search)
    const { id_token, refresh_token, state } = queryParams
    // 处理OIDC回调逻辑
    if (id_token && refresh_token) {
      const decoded = UserService.decodeToken(id_token)
      if (decoded && decoded.exp) {
        UserService.processTokenData({
          access_token: id_token,
          refresh_token,
          expires_in: decoded.exp
        })
      }
    }
    if (state) {
      console.log('state', state)
      const redirectUrl = new URL(state)
      const { protocol, host } = redirectUrl
      console.log('redirectUrl', {
        protocol,
        host
      })
      if (protocol.includes(`${process.env.VUE_APP_PROTOCOL}`) && host.includes(`${process.env.VUE_APP_HOST_NEW}`)) {
        // window.location.href = state
        window.opener.postMessage(queryParams, state)
      }
    }
  }
}
